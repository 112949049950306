import React from "react"
import HTMLReactParser from "html-react-parser"
// import TickImage from "../../images/Vector.png"
// import XImage from "../../images/X.png"
import PackageTick from "../../images/packagetick.svg"
import PackageClose from "../../images/packageclose.svg"


const TableBody = props => {
  console.log(props)
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          {props?.headerItem?.map((data, index) => {
            return (
              <th className="sub_header_section">
                {data.info ?
                  <p className="info"> {data.info}</p>
                  :
                  <p className="info empty"></p>
                }
                <p className="percentage">{data.Percentage}</p>
                <p className="title_header">{data.Title ? HTMLReactParser(data.Title) : null}</p>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {props.item.map((data, index) => {
          return (
            <tr className={index % 2 === 0 ? "blur_row" : ""}>
              <td className="content_data">{data.Title}</td>
              <td>
                {data.Finder === true ? (
                  <img src={PackageTick} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={PackageClose} alt="tickImage" />
                )}
              </td>
              <td>
                {data.CollectionValue === true ? (
                  <img src={PackageTick} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={PackageClose} alt="tickImage" />
                )}
              </td>
              <td>
                {data.Managed === true ? (
                  <img src={PackageTick} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={PackageClose} alt="tickImage" />
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableBody
