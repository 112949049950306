import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import ScrollAnimation from "react-animate-on-scroll"
import "./PackageBlock.scss"
import { isMobileOnly } from "react-device-detect"
import OurPackages from "../OurPackages/OurPackages"
import CaretDown from "../../images/caret-down.png"

const PackageBlock = props => {
  const blockDetails = props.Module

  const [show, setShow] = useState(false)
  const handleFunction = () => {
    setShow(!show)
  }
  return (
    <div className="package_block_wrapper" id="package-block">
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
            <div className="title_content">
            {blockDetails.Title_Content
                ? HTMLReactParser(blockDetails.Title_Content)
                : null}
            </div>
            <Row>
                {blockDetails && blockDetails.Package_Block_Content.map((list, i) => {
                return(
                    <Col xl="4" lg="4" md="12" sm="12">
                        <div className={`${list.Title === "Managed" ? "shadow-plan" : ""} service`}>
                        <h3>{list.Percentage}<span className="ml-2"> inc. VAT</span></h3>
                        <p>{list.Title}</p>
                        <div className="content">
                        <p className="dull-color">{list.Package_Content ? HTMLReactParser(list.Package_Content) : null}</p>
                            {<a className={`btn btn-outline yellow-btn`} href={`/property-services/sell/${list.CTA_Link.Primary_URL}`}>{list.CTA_Label}</a>}
                        </div>
                        {list.recommended ? 
                            <div className="badge">RECOMMENDED</div> : ""
                        }
                        </div>
                    </Col>
                 )
                })}
            </Row>
            <div className="bottom">
                <p className="dull-color">{blockDetails.Bottom_Text}</p>
                <a className="block_btn btn btn-outline" onClick={() => handleFunction()} href={blockDetails.Bottom_CTA_Link}>{blockDetails.Bottom_CTA_Label}<img className={show == true ? "down" : ""} src={CaretDown} alt="caret-img"/></a>
            </div>
          </ScrollAnimation>
      </Container>
      {show === true ? 
      <OurPackages 
        List_Component={[
        {Percentage: "Let Only", Title: "9.6% inc. Vat"},
        {Percentage: "Rent Collection", Title: "12% inc. Vat"},
        {Percentage: "Fully Managed", Title: "15% inc. Vat", info:"Most Popular"}, 
        ]} 
        Tite_Content={`<div class="feature-block-title"><h2>Compare Our Full Packages</h2><p>Find the perfect package to suits your needs.</p></div>`}
        publish={true}
        className={`feature-table-block`}
        handleFunction={handleFunction}/> : ""}
    </div>
  )
}
export default PackageBlock
